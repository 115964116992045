/**
 * IRIS R&D Group Inc. All rights reserved.
 *
 * Author: Lucien Chu
 * Create Date: Jan 12, 2021
 *
 * Description: a modal component that show informative information to user
 */
import React, { useEffect } from "react";
import styles from "./IrisModal.module.scss";
import irisCityLogo from "../../assets/IrisCityLogo.png";
import { Button } from "@material-ui/core";
export default function IrisModal(props) {
  const { title, message } = props;

  const closeModal = () => {
    const modal = document.getElementById("irisModal");
    modal.classList.toggle(styles.show);
    props.onClose();
    // console.log("modal.classList", modal.classList);
  };

  const showModal = () => {
    const modal = document.getElementById("irisModal");
    modal.classList.toggle(styles.show);
  };

  useEffect(() => {
    if (title && message && title !== "" && message !== "") {
      const modal = document.getElementById("irisModal");
      modal.classList.toggle(styles.show);
      document.getElementById("modalCloseButton").focus();
    }
  }, [title, message]);

  return (
    <div>
      <div id="irisModal" className={styles.modalContainer}>
        <div className={styles.modalContent}>
          <div className={styles.modalHeader}>
            <img
              className={styles.cityLogo}
              src={irisCityLogo}
              alt="iris-logo"
            />
            <p className={styles.modalTitle}>{title}</p>
            <span className={styles.closeBtn} onClick={closeModal}>
              &times;
            </span>
          </div>
          <div className={styles.modalBody}>
            <p
              className={styles.modalMessage}
              dangerouslySetInnerHTML={{
                __html: message,
              }}
            ></p>
          </div>
          <div className={styles.modalFooter}>
            <Button
              variant="outlined"
              onClick={closeModal}
              id="modalCloseButton"
            >
              Dismiss
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

/**
 * Change Log:
 *
 * Change Date: Jan 12, 2021
 *
 * Description: component is created and documentation is added.
 */

/**
 * Change Log:
 *
 * Change Date: Jan 25, 2021
 *
 * Description:
 * updates: component would be able to render HTML element (styles) passed by
 *          the message property
 */
