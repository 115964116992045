// import DeviceSummary from "../../components/DeviceSummary";

export const MODULE_LIST = [
  { title: "Levels of Service:", key: "levels_of_service" },
  { title: "MMS Patrol Report:", key: "patrol_report" },
  { title: "Unresolved Issues:", key: "unresolved_issues" },
];
export const OPEN_WEATHER_CITY_LIST = [
  { name: "Toronto", province: "Ontario", code: 6167865 },
  { name: "London", province: "Ontario", code: 6058560 },
  { name: "Oakville", province: "Ontario", code: 6092122 },
  { name: "Hamilton", province: "Ontario", code: 5969785 },
  { name: "Burlington", province: "Ontario", code: 5911592 },
  { name: "Sagamihara", province: "Kanagawa Prefecture", code: 1853295 },
  { name: "Vaughan", province: "Ontario", code: 6173577 },
  { name: "Curitiba", province: "Paraná", code: 6322752 },
];

// Currently, Reset all user layout preference before commenting out any one of the following entry
export const DEFAULT_LAYOUT = {
  patrol_report: {
    title: "MMS Patrol Report",
    layout: { x: 0, y: 0, w: 6, h: 21, minW: 6 },
    titlePosition: { top: 17, left: 17 },
    titleSize: { w: 265, h: 35 },
    link: "https://irisradgroup.maps.arcgis.com/apps/opsdashboard/index.html#/f6e69027f29f41a7920ac23903bf756f",
  },
  rri_list: {
    title: "Daily Alerts",
    layout: { x: 0, y: 0, w: 6, h: 21, minW: 6 },
    titlePosition: { top: 17, left: 17 },
    titleSize: { w: 165, h: 35 },
    link: "https://irisradgroup.maps.arcgis.com/apps/opsdashboard/index.html#/62d04f7cd54745cfa11479481075a5e9",
  },
  // device_summary: {
  //   title: "Fleet Management",
  //   layout: { x: 0, y: 0, w: 6, h: 21, minW: 6 },
  //   titlePosition: { top: 17, left: 17 },
  //   titleSize: { w: 245, h: 35 },
  //   child: DeviceSummary(),
  // },
  reports: {
    title: "Reports",
    layout: { x: 6, y: 0, w: 6, h: 9, minW: 6 },
    titlePosition: { top: 17, left: 17 },
    titleSize: { w: 120, h: 35 },
    link: "https://irisradgroup.maps.arcgis.com/apps/opsdashboard/index.html#/98c2891a4a7b4a49b9244f4cf3d9f5c3",
  },
  waste_participation: {
    title: "Waste Participation",
    layout: { x: 6, y: 9, w: 6, h: 12, minW: 6 },
    titlePosition: { top: 17, left: 24 },
    titleSize: { w: 270, h: 35 },
    link: "https://irisradgroup.maps.arcgis.com/apps/opsdashboard/index.html#/195349899c1745ca9c865761b2402599",
  },
  unresolved_issues: {
    title: "Unresolved Issues",
    layout: { x: 6, y: 0, w: 6, h: 7, minW: 6 },
    titlePosition: { top: 17, left: 17 },
    titleSize: { w: 250, h: 35 },
    link: "https://irisradgroup.maps.arcgis.com/apps/opsdashboard/index.html#/73a2f2dc7c124fdab94eb262f9d6e0c8",
  },
  levels_of_service: {
    title: "Levels of Service",
    layout: { x: 6, y: 15, w: 6, h: 14, minW: 6 },
    titlePosition: { top: 17, left: 17 },
    titleSize: { w: 230, h: 35 },
    link: "https://arcgis.com/apps/opsdashboard/index.html#/805f9f77f8304f00aae5cf082543c922",
  },
  cracks_london: {
    title: "London Crack Alerts",
    layout: { x: 0, y: 0, w: 12, h: 14, minW: 12, minH: 6 },
    titlePosition: { top: 17, left: 17 },
    titleSize: { w: 230, h: 35 },
    link: "https://irisradgroup.maps.arcgis.com/apps/webappviewer/index.html?id=4ed879c832444c9ab9a9c78c652e7600",
  },
  london_route_coverage: {
    title: "London Route Coverage",
    layout: { x: 24, y: 24, w: 12, h: 21, minW: 6 },
    titlePosition: { top: 17, left: 17 },
    titleSize: { w: 265, h: 35 },
    link: "https://irisradgroup.maps.arcgis.com/apps/webappviewer/index.html?id=c0933da6024a4e37b882d7ce6941fbf4",
  },
  hamilton_waste_collection_participation: {
    title: "Hamilton Waste Collection Participation",
    layout: { x: 0, y: 0, w: 6, h: 21, minW: 6 },
    titlePosition: { top: 17, left: 17 },
    titleSize: { w: 265, h: 35 },
    link: "https://irisradgroup.maps.arcgis.com/apps/opsdashboard/index.html#/06d4f45a31c5465db91c454e6b1c9945",
  },
  hamilton_jubilee_bins: {
    title: "Hamilton Jubilee Bins",
    layout: { x: 0, y: 0, w: 6, h: 16, minW: 6 },
    titlePosition: { top: 17, left: 17 },
    titleSize: { w: 265, h: 35 },
    link: "https://irisradgroup.maps.arcgis.com/apps/opsdashboard/index.html#/5702e7347cc44148a03cde049a6beb91",
  },
  london_dailyreport: {
    title: "London MMS Patrol Report",
    layout: { x: 0, y: 0, w: 6, h: 16, minW: 6 },
    titlePosition: { top: 17, left: 17 },
    titleSize: { w: 265, h: 35 },
    link: "IRIS:/module/London_DailyAlerts",
  },
  hamilton_raven_detection: {
    title: "Hamilton Raven Detection",
    layout: { x: 6, y: 0, w: 12, h: 24, minW: 6 },
    titlePosition: { top: 17, left: 17 },
    titleSize: { w: 265, h: 35 },
    link: "https://www.arcgis.com/apps/dashboards/e51d9057f0d94aeeb5b4ca98c8401fe0",
  },
  hamilton_participation_study: {
    title: "Hamilton Participation Study",
    layout: { x: 6, y: 0, w: 12, h: 24, minW: 6 },
    titlePosition: { top: 17, left: 17 },
    titleSize: { w: 265, h: 35 },
    link: "https://www.arcgis.com/apps/dashboards/712af7f7ec444136bcdfbe4c2181fc06",
  },
  london_signs_detection_module: {
    title: "Signs Detection Module",
    layout: { x: 6, y: 0, w: 12, h: 24, minW: 6 },
    titlePosition: { top: 17, left: 17 },
    titleSize: { w: 265, h: 35 },
    link: "https://www.arcgis.com/apps/dashboards/e51d9057f0d94aeeb5b4ca98c8401fe0",
  },
  japan_route_coverage: {
    title: "Japan Route Coverage",
    layout: { x: 6, y: 0, w: 12, h: 24, minW: 6 },
    titlePosition: { top: 17, left: 17 },
    titleSize: { w: 265, h: 35 },
    link: "https://irisradgroup.maps.arcgis.com/apps/webappviewer/index.html?id=cf5fdddc4f0a40828a18eb2b6390b0d3",
  },
  japan_row_signs: {
    title: "Japan ROW Signs",
    layout: { x: 6, y: 0, w: 12, h: 24, minW: 6 },
    titlePosition: { top: 17, left: 17 },
    titleSize: { w: 265, h: 35 },
    link: "https://www.arcgis.com/apps/dashboards/1ff87b22adc24511a399f1415390448a",
  },
  japan_alerts: {
    title: "Japan Alerts",
    layout: { x: 6, y: 0, w: 12, h: 24, minW: 6 },
    titlePosition: { top: 17, left: 17 },
    titleSize: { w: 265, h: 35 },
    link: "https://www.arcgis.com/apps/dashboards/80f9a6c7c22042d69fd8d0197bfd10e6",
  },
  japan_pavement_distress: {
    title: "Japan Pavement Distress",
    layout: { x: 6, y: 0, w: 12, h: 24, minW: 6 },
    titlePosition: { top: 17, left: 17 },
    titleSize: { w: 265, h: 35 },
    link: "https://www.arcgis.com/apps/dashboards/777486c3777f4a57839145af5bf873b3",
  },
  vaughan_compliance: {
    title: "Compliance Dashboard",
    layout: { x: 6, y: 0, w: 12, h: 24, minW: 6 },
    titlePosition: { top: 17, left: 17 },
    titleSize: { w: 265, h: 35 },
    link: "https://www.arcgis.com/apps/dashboards/0dcd518f8b534b0b86603c5567b26cf5",
  },
  vaughan_route_coverage: {
    title: "Route Coverage",
    layout: { x: 6, y: 0, w: 12, h: 24, minW: 6 },
    titlePosition: { top: 17, left: 17 },
    titleSize: { w: 265, h: 35 },
    link: "https://irisradgroup.maps.arcgis.com/apps/webappviewer/index.html?id=676979d359ea40f5a0eb51a00fa3d531",
  },
  brazil_video: {
    title: "Brazil Video",
    layout: { x: 6, y: 0, w: 12, h: 24, minW: 6 },
    titlePosition: { top: 17, left: 17 },
    titleSize: { w: 265, h: 35 },
    link: "https://www.arcgis.com/apps/dashboards/e43221ee3cab4a04904170da4e06e221",
  },
  brazil_row_detection: {
    title: "Brazil Row Detection",
    layout: { x: 6, y: 0, w: 12, h: 24, minW: 6 },
    titlePosition: { top: 17, left: 17 },
    titleSize: { w: 265, h: 35 },
    link: "https://www.arcgis.com/apps/dashboards/3f5378db33344ceea98261ec0d9d3b71",
  },
  vaughan_heat_map: {
    title: "Vaughan Heat Map",
    layout: { x: 6, y: 0, w: 12, h: 24, minW: 6 },
    titlePosition: { top: 17, left: 17 },
    titleSize: { w: 265, h: 35 },
    link: "https://irisradgroup.maps.arcgis.com/apps/webappviewer/index.html?id=207932834fd84608a1f364f57482df0d",
  },
  vaughan_work_order: {
    title: "Vaughan Work Order",
    layout: { x: 6, y: 0, w: 12, h: 24, minW: 6 },
    titlePosition: { top: 17, left: 17 },
    titleSize: { w: 265, h: 35 },
    link: "https://www.arcgis.com/apps/dashboards/38d2f5ab9ac84cce93ff9790434e342e",
  },
};
