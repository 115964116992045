import React, { useState, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import FullscreenIcon from "@material-ui/icons/Fullscreen";
import IconButton from "@material-ui/core/IconButton";
import RefreshIcon from "@material-ui/icons/Refresh";
import GetAppIcon from "@material-ui/icons/GetApp";
import MinimizeIcon from "@material-ui/icons/Minimize";
import Grid from "@material-ui/core/Grid";
import { HomePageContext } from "./MainContext";
import { AuthContext } from "./AuthContext";
import { IRISGO_DOWNLOAD_CSV_URL } from "../utils/env/urls";

// MODULES
import London_DailyAlerts from "../modules/London_DailyAlerts/London_DailyAlerts";

const useStyles = makeStyles(() => ({
  root: {
    width: "100%",
    height: "100%",
    flexDirection: "column",
  },
  widget: {
    flex: 1,
  },
  iframe: {
    width: "100%",
    height: "100%",
    border: 0,
  },
  toolBar: {
    height: 24,
    width: "100%",
    backgroundColor: "#91b8c9",
    justifyContent: "flex-end",
    "&:hover": {
      background: "#000",
      opacity: 0.4,
    },
    cursor: "move",
  },
  dragArea: {
    // zIndex: "98",
    // position: "absolute",
    // top: (props) => `${props.titlePosition.top}px`,
    // left: (props) => `${props.titlePosition.left}px`,
    // width: (props) => `${props.titleSize.w}px`,
    // height: (props) => `${props.titleSize.h}px`,
    // "&:hover": {
    //   background: "#fff",
    //   // border: "1px solid black",
    //   opacity: 0.5,
    // },
    // cursor: "move",
  },
  fullscreenButton: {
    width: "24px",
    height: "24px",
    borderRadius: 0,
  },
  fullScreenIcon: {
    fontSize: 24,
    color: "white",
  },
}));

export default function WidgetHolder({
  moduleName,
  widgetUrl,
  child,
  ...props
}) {
  const { setFullScreenModule } = useContext(HomePageContext);
  const {
    contextPreference,
    setContextPreference,
    contextUsername,
    contextCity,
  } = useContext(AuthContext);
  const [reloadVar, setReloadVar] = useState(0);
  const classes = useStyles(props);

  function modulePicker(moduleName) {
    // react-based alerts

    var module;
    switch (moduleName) {
      // ADD NEW REACT-BASED MODULES HERE
      case "london_dailyreport":
        module = <London_DailyAlerts key={reloadVar} ModuleName={moduleName} />;
        break;

      default:
        return <div>Module does not exist</div>;
    }
    return <div style={{ width: "100%", height: "100%" }}>{module}</div>;
  }

  const module = widgetUrl
    ? widgetUrl.substring(0, 7) == "MODULE:"
      ? widgetUrl.substring(7, widgetUrl.length)
      : null
    : null;
  return (
    <Grid container className={classes.root}>
      <Grid item container className={classes.toolBar} style={{}}>
        <Grid item>
          <Tooltip title="Hide" placement="top">
            <IconButton
              onClick={() => {
                contextPreference[
                  "iriscity_dashboard_layout"
                ] = contextPreference["iriscity_dashboard_layout"].filter(
                  (i) => i.i !== moduleName
                );
                setContextPreference({ ...contextPreference });
              }}
              className={classes.fullscreenButton}
            >
              <MinimizeIcon className={classes.fullScreenIcon} />
            </IconButton>
          </Tooltip>
        </Grid>
        <Grid item>
          <Tooltip title="Refresh" placement="top">
            <IconButton
              onClick={() => setReloadVar(reloadVar + 1)}
              className={classes.fullscreenButton}
            >
              <RefreshIcon className={classes.fullScreenIcon} />
            </IconButton>
          </Tooltip>
        </Grid>
        <Grid item>
          <Tooltip title="Export CSV" placement="top">
            <a
              href={`${IRISGO_DOWNLOAD_CSV_URL}?key=${moduleName}`}
              target="_blank"
              onClick={() =>
                window.gtag(
                  "event",
                  `${contextCity.name}_${contextUsername}_download_${moduleName}`
                )
              }
            >
              <IconButton className={classes.fullscreenButton}>
                <GetAppIcon className={classes.fullScreenIcon} />
              </IconButton>
            </a>
          </Tooltip>
        </Grid>
        <Grid item>
          <Tooltip title="Fullscreen" placement="top">
            <IconButton
              onClick={() => setFullScreenModule(moduleName)}
              className={classes.fullscreenButton}
            >
              <FullscreenIcon className={classes.fullScreenIcon} />
            </IconButton>
          </Tooltip>
        </Grid>
      </Grid>
      <Grid
        item
        className={classes.widget}
        style={{ width: "100%", height: "calc(100% - 25px)" }}
      >
        {widgetUrl ? (
          widgetUrl.substring(0, 7) == "MODULE" ? (
            modulePicker(moduleName)
          ) : (
            <iframe
              className={classes.iframe}
              key={reloadVar}
              src={widgetUrl}
              title="Widget"
            ></iframe>
          )
        ) : (
          child
        )}
      </Grid>
    </Grid>
  );
}
