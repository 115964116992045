import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  root: {
    width: "100%",
    height: "100%",
    margin: 0,
  },
  iframe: {
    width: "100%",
    height: "100%",
    border: 0,
  },
}));

export default function WidgetHolder({
  moduleName,
  widgetUrl,
  child,
  ...props
}) {
  const classes = useStyles(props);
  return (
    <div className={classes.root}>
      {widgetUrl ? (
        <iframe
          className={classes.iframe}
          src={widgetUrl}
          title="Widget"
        ></iframe>
      ) : (
        child
      )}
    </div>
  );
}
