import React, { Component, useRef } from "react";

import {
  getLabel,
  updateLabelResolution,
  updateLabelComment,
} from "../../../../utils/requests";
import { RenderComments } from "../rendercomments/RenderComments";
import { AuthContext } from "../../../../components/AuthContext";
import withModal from "../../../../HOC/withModal";
import "../../London_DailyAlerts.css";
import back_icon from "../../../shared_assets/back.png";

// displays a single issue passed by the Issue prop
class ViewIssue extends Component {
  constructor() {
    super();

    this.state = {
      comment: "",
      refreshCount: 0,
    };

    this.textareaRef = React.createRef();
  }

  submitComment(event) {
    this.textareaRef.current.disabled = true;
    let datetime = new Date();
    updateLabelComment(
      this.props.Issue.label_id,
      this.props.ContextToken,
      this.props.UserID,
      datetime.toLocaleString(),
      this.state.comment
    )
      .then((result) => {
        getLabel(this.props.Issue.label_id, this.props.ContextToken)
          .then((result) => {
            this.props.Issue.Comments = result.comments;

            this.setState({
              comment: "",
              refreshCount: this.state.refreshCount + 1,
            });

            this.textareaRef.current.disabled = false;
            this.textareaRef.current.focus();
          })
          .catch((error) => {
            alert("An error occurred while reloading comments (" + error + ")");
            this.textareaRef.current.disabled = false;
            this.textareaRef.focus();
          });
      })
      .catch((error) => {
        alert("An error occurred while adding a comment (" + error + ")");
        this.textareaRef.current.disabled = false;
        this.textareaRef.focus();
      });
  }

  refresh() {
    this.setState({ refreshCount: this.state.refreshCount + 1 });
  }

  render() {
    if (this.state.refreshCount >= 0) {
      return (
        <div className="moduleWrapper">
          <div style={{ display: "flex", flexDirection: "row" }}>
            <div
              style={{ width: "60%", display: "flex", flexDirection: "column" }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <img
                  style={{
                    cursor: "pointer",
                    width: 32,
                    height: 28,
                    paddingRight: 10,
                  }}
                  onClick={() => {
                    this.props.ChooseFocus(null);
                  }}
                  src={back_icon}
                />
                <div className="mms-report-title-large">
                  <p>
                    <b>Class {this.props.Issue.mms_class}:</b>
                    {this.props.Issue.mms_damage_typ}
                  </p>
                </div>
              </div>
              <img
                style={{ height: "100%", borderRadius: 25, maxHeight: 300 }}
                src={this.props.Issue.image + this.props.ContextToken}
              />
            </div>
            <div
              style={{
                width: "40%",
                display: "flex",
                flexDirection: "column",
                padding: 10,
              }}
            >
              <p className="mms-report-title-large">Issue Details:</p>
              <p style={{ margin: 0, marginBottom: 15 }}>
                {this.props.Issue.date_collected +
                  " at " +
                  this.props.Issue.timestamp}
              </p>

              <h4 className="info-header">Service Window:</h4>
              <p style={{ margin: 0, marginBottom: 15 }}>
                Repair by {this.props.Issue.mms_due_display}
              </p>

              <h4 className="info-header">Location:</h4>
              <p style={{ margin: 0, marginBottom: 15 }}>
                {this.props.Issue.Address}
              </p>

              <h4 className="info-header">GPS:</h4>
              <p style={{ margin: 0, marginBottom: 15 }}>
                {this.props.Issue.long.substring(0, 10)},
                {this.props.Issue.lat.substring(0, 10)}
              </p>

              <h4 className="info-header">Device:</h4>
              <p style={{ margin: 0, marginBottom: 15 }}>
                {this.props.Issue.device}
              </p>

              <div style={{ display: "flex", flexDirection: "row" }}>
                <div style={{ width: "50%" }}>
                  <h4 className="info-header">MMS Class:</h4>
                  <p style={{ margin: 0, marginBottom: 15 }}>
                    {this.props.Issue.mms_class}
                  </p>
                </div>

                <div style={{ width: "50%" }}>
                  <h4 className="info-header">MMS District:</h4>
                  <p style={{ margin: 0, marginBottom: 15 }}>
                    {this.props.Issue.mms_district}
                  </p>
                </div>
              </div>

              <div style={{ display: "flex", flexDirection: "row" }}>
                <div style={{ width: "50%" }}>
                  <h4 className="info-header">Ward:</h4>
                  <p style={{ margin: 0, marginBottom: 15 }}>
                    {this.props.Issue.ward}
                  </p>
                </div>
                <div style={{ width: "50%" }}>
                  <h4 className="info-header">ID:</h4>
                  <p style={{ margin: 0, marginBottom: 15 }}>
                    {this.props.Issue.datapoint_id}
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div
            style={{
              border: "1px solid #888888",
              padding: 10,
              paddingTop: 5,
              borderRadius: 5,
            }}
          >
            <p className="comments-header">Comments</p>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginBottom: 10,
                }}
              >
                <div>
                  <div
                    style={{
                      backgroundColor: "#aaffaa",
                      borderRadius: 30,
                      width: 40,
                      height: 40,
                      alignItems: "center",
                      justifyContent: "center",
                      textAlign: "center",
                    }}
                  >
                    <div
                      style={{
                        fontSize: "14pt",
                        fontWeight: "bold",
                        paddingTop: 8,
                      }}
                    >
                      {this.props.Initials}
                    </div>
                  </div>
                </div>
                <textarea
                  ref={this.textareaRef}
                  value={this.state.comment}
                  onChange={(event) => {
                    this.setState({ comment: event.target.value });
                  }}
                  onKeyUp={(event) => {
                    if (event.key === "Enter") this.submitComment();
                  }}
                  style={{
                    marginLeft: 15,
                    border: "1px solid #888888",
                    width: "100%",
                    multiline: "true",
                  }}
                ></textarea>
              </div>

              <div>
                {this.props.Issue.Comments
                  ? this.props.Issue.Comments.reverse().map((comment) => {
                      return (
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "top",
                            marginTop: 15,
                          }}
                        >
                          <div
                            style={{
                              backgroundColor:
                                comment.user_id === this.props.UserID
                                  ? "#aaffaa"
                                  : "#aaaaff",
                              borderRadius: 30,
                              width: 30,
                              height: 30,
                              alignItems: "center",
                              justifyContent: "center",
                              textAlign: "center",
                              marginRight: 10,
                            }}
                          >
                            <div
                              style={{
                                fontSize: "12pt",
                                fontWeight: "bold",
                                paddingTop: 6,
                              }}
                            >
                              {comment.first_name.substring(0, 1) +
                                comment.last_name.substring(0, 1)}
                            </div>
                          </div>
                          <div>
                            <span
                              style={{ fontSize: "16pt", fontWeight: "bold" }}
                            >
                              {comment.first_name + " " + comment.last_name}
                            </span>
                            <span style={{ fontSize: "9pt" }}>
                              {" wrote at " + comment.datetime}
                            </span>
                            <br />
                            {comment.comment}
                          </div>
                        </div>
                      );
                    })
                  : null}
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return <div>Refresh error</div>;
    }
  }
}

export default withModal(ViewIssue);
