export const GET_YESTERDAY = new Date() - 3600000 * 24;

export const FILTER_CITY_LAYOUT = (
  preference,
  cityWidgets,
  totalWidgets,
  disabled_widgets
) => {
  let cityTotalWidget = {};
  let savedPreference = preference["iriscity_dashboard_layout"];

  //filter preference page widget list
  cityWidgets.forEach((widget) => {
    if (
      //if the disabled widget does not contain the current widget
      !disabled_widgets.some(
        (disabled_widget) => disabled_widget.key === widget.key
      )
    ) {
      // add the widget to the preference page's total widget list from totalWidget list
      cityTotalWidget[widget.key] = totalWidgets[widget.key];
    }
  });

  // filter preference
  // If profile has no layout preference use default
  if (savedPreference === undefined || savedPreference === []) {
    savedPreference = [];
    Object.keys(cityTotalWidget).map((widgetKey) => {
      savedPreference.push({
        i: widgetKey,
        ...cityTotalWidget[widgetKey].layout,
      });
      return null;
    });
  } else {
    // If layout preference is found filter out disabled widgets
    disabled_widgets.map((w) => {
      savedPreference = savedPreference.filter((layout) => layout.i !== w.key);
      return null;
    });
  }
  preference["iriscity_dashboard_layout"] = savedPreference;
  return {
    defaultTotalLayout: cityTotalWidget,
    preferenceWithFilteredLayout: preference,
  };
};

export const PROCESS_MOBILE_USAGE_DATA = (usageData, deviceList) => {
  // for each damage type add related data to it
  let processedList = deviceList;
  for (const i in processedList) {
    processedList[i].usage = [];
    processedList[i].summary = { distance: 0, data: 0 };
    usageData.forEach((data) => {
      if (data.device_id === processedList[i].id) {
        processedList[i].usage = [...processedList[i].usage, data];
        processedList[i].summary.distance += data.distance;
        processedList[i].summary.data += data.data;
      }
    });
  }
  let filteredList = processedList.filter(
    (value) => value.summary.distance !== 0
  );

  let usageSummary = {
    id: 0,
    tag: "Summary",
    usage: [],
    summary: { distance: 0, data: 0 },
  };

  filteredList.forEach(function (device) {
    usageSummary.usage.push({
      name: device.tag,
      city: device.city.name,
      distance: device.summary.distance,
      data: device.summary.data,
    });
    usageSummary.summary.distance += device.summary.distance;
    usageSummary.summary.data += device.summary.data;
  });

  // combine and return results
  return [usageSummary, ...filteredList];
};
