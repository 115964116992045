import React, { useState } from "react";

export const AuthContext = React.createContext();

export const AuthContextProvider = ({ children }) => {
  const [contextUsername, setContextUsername] = useState();
  const [contextUserID, setContextUserID] = useState();
  const [contextToken, setContextToken] = useState();
  const [contextGroupInfo, setContextGroupInfo] = useState();
  const [contextPreference, setContextPreference] = useState();
  const [contextCity, setContextCity] = useState();
  const [contextWidgetList, setContextWidgetList] = useState();
  const [contextAuthStr, setContextAuthStr] = useState();

  const contextValue = {
    contextUsername,
    contextUserID,
    contextToken,
    contextGroupInfo,
    contextPreference,
    contextCity,
    contextWidgetList,
    contextAuthStr,
    
    setContextWidgetList,
    setContextUsername,
    setContextUserID,
    setContextToken,
    setContextGroupInfo,
    setContextPreference,
    setContextCity,
    setContextAuthStr,
  };

  return (
    <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
  );
};
