import React from "react";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles/";
import { IRIS_RED, IRIS_BLUE, IRIS_LIGHT_BLUE, IRIS_GREEN } from "./colors";
const theme = createMuiTheme({
  palette: {
    primary: {
      main: IRIS_BLUE,
      light: IRIS_LIGHT_BLUE,
    },
    secondary: {
      main: IRIS_RED,
    },
    success: {
      main: IRIS_GREEN,
    },
  },
  typography: {
    button: {
      fontSize: "1.1rem",
      textTransform: "none",
      fontWeight: 600,
    },
    overline: {
      lineHeight: 1.85,
      letterSpacing: "0.00938em",
      fontSize: "0.80rem",
    },
    caption: {
      lineHeight: 2.5,
      fontSize: "0.72rem",
    },
    h5: {
      fontSize: "1.5rem",
      letterSpacing: "0.01em",
      fontWeight: 600,
    },
    h4: {
      fontWeight: 900,
    },
  },
  overrides: {
    MuiInputLabel: {
      root: {
        fontSize: "1.125rem",
        "@media (max-width: 1440px)": {
          fontSize: "1rem",
        },
        "@media (max-width: 1024px)": {
          fontSize: "0.875rem",
        },
      },
    },
  },
});

const IRISThemeProvider = (props) => {
  return <ThemeProvider theme={theme}>{props.children}</ThemeProvider>;
};

export default IRISThemeProvider;
