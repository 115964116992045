const IRIS_RED = "#d00205";
const BUTTON_HEIGHT = 36;
const BUTTON_BORDER_RADIUS = 3;
const IRIS_BACKGROUND = `linear-gradient(45deg, ${IRIS_RED} 30%, #FF8E53 90%)`;
const IRIS_BLUE = "#3384bd";
const IRIS_LIGHT_BLUE = "#E2F0FF";
const IRIS_GREEN = "#00CC00";

export {
  IRIS_RED,
  BUTTON_HEIGHT,
  BUTTON_BORDER_RADIUS,
  IRIS_BACKGROUND,
  IRIS_BLUE,
  IRIS_LIGHT_BLUE,
  IRIS_GREEN,
};
