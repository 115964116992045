import React, { useContext, useEffect } from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { AuthContext } from "../components/AuthContext";
import RGL, { WidthProvider } from "react-grid-layout";
import WidgetHolder from "./WidgetHolder";
import Weather from "./Weather";
import Preference from "./Preference";
import FullscreenModule from "./FullscreenModule";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import IrisGoLogo from "../assets/IrisGoLogo.png";
import { Typography } from "@material-ui/core";
import { IRISGO_USER_PROFILE_UPDATE_REQUEST } from "../utils/requests";
import { HomePageContext } from "./MainContext";
import { GIS_MAP_URL } from "../utils/env/urls";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import IrisModal from "./userPreference/IrisModal";

const GridLayout = WidthProvider(RGL);

const useStyles = makeStyles((theme) => ({
  main: {
    padding: theme.spacing(1),
  },
  infoContainer: {
    padding: theme.spacing(1),
  },
  rightInfo: {
    float: "right",
    flexDirection: "column",
    alignItems: "flex-end",
    justifyContent: "space-around",
  },
  button: {
    width: "300px",
    height: "35px",
    color: "white",
    backgroundColor: theme.palette.primary.main,
    borderRadius: "16px",
  },
  irisGoLogo: {
    objectFit: "contain",
    height: "30px",
  },
  deploymentInfo: { justifyContent: "flex-end", alignItems: "flex-end" },
  preference: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));

export default function Dashboard() {
  const classes = useStyles();

  const {
    contextUserID,
    contextToken,
    contextPreference,
    contextCity,
    contextUsername,
    setContextPreference,
    contextWidgetList,
    contextAuthStr,
  } = useContext(AuthContext);

  const {
    showPreference,
    setShowPreference,
    fullScreenModule,
    setFullScreenModule,
  } = useContext(HomePageContext);

  const onLayoutChange = (layout) => {
    contextPreference["iriscity_dashboard_layout"] = layout;
    setContextPreference({ ...contextPreference });
    window.gtag(
      "event",
      `${contextCity.name}_${contextUsername}_change_layout`,
      {
        username: contextUsername,
        city: contextCity.name,
      }
    );
  };

  useEffect(() => {
    IRISGO_USER_PROFILE_UPDATE_REQUEST(
      contextUserID,
      contextPreference,
      contextToken
    )
      .then((result) => {
        console.log(result);
      })
      .catch((error) => {
        console.log(error);
      });
  });

  // useEffect(() => {
  //   window.gtag("event", `${contextCity.name}_${contextUsername}_login`, {
  //     username: contextUsername,
  //   });
  // }, [contextCity.name, contextUsername]);

  var firsttime = true;

  useEffect(() => {
    if (firsttime) {
      //alert('contextAuthStr = ' + contextAuthStr);
      firsttime = false;
    }
  });

  return (
    <div className={classes.main}>
      <Modal
        className={classes.preference}
        open={showPreference}
        onClose={() => setShowPreference(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={showPreference}>
          <Preference />
        </Fade>
      </Modal>
      <Modal
        className={classes.preference}
        open={fullScreenModule !== ""}
        onClose={() => setFullScreenModule("")}
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={showPreference}>
          <FullscreenModule moduleName={fullScreenModule} />
        </Fade>
      </Modal>
      <Grid container className={classes.infoContainer}>
        <Grid item md={6} xs={12} className={classes.item}>
          <Weather
            cityCode={
              "open_weather_city_id" in contextPreference
                ? contextPreference.open_weather_city_id
                : 5911592
            }
          />
        </Grid>
        <Grid item container md={6} xs={12} className={classes.rightInfo}>
          <Grid item>
            <Button
              variant="contained"
              className={classes.button}
              disableElevation
              onClick={() => window.open(GIS_MAP_URL, "_blank")}
            >
              Search GIS Map
            </Button>
          </Grid>
          <Grid item container className={classes.deploymentInfo}>
            <Grid item>
              <img
                src={IrisGoLogo}
                className={classes.irisGoLogo}
                alt="irisGO™"
              />
            </Grid>
            <Grid item>
              <Typography variant={"button"} display={"inline"}>
                Devices deployed:{" "}
                {contextCity["device_count"]
                  ? contextCity["device_count"]
                  : "-"}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <GridLayout
        className="layout"
        cols={12}
        rowHeight={30}
        layout={contextPreference["iriscity_dashboard_layout"]}
        onLayoutChange={(layout) => onLayoutChange(layout)}
      >
        {contextPreference["iriscity_dashboard_layout"].map((layout) => {
          return (
            <div key={layout.i} data-grid={contextWidgetList[layout.i].layout}>
              <WidgetHolder
                moduleName={layout.i}
                titlePosition={contextWidgetList[layout.i].titlePosition}
                titleSize={contextWidgetList[layout.i].titleSize}
                widgetUrl={
                  contextWidgetList[layout.i].link.substring(0, 5) === "IRIS:"
                    ? contextWidgetList[layout.i].link.substring(
                        5,
                        contextWidgetList[layout.i].link.length
                      ) +
                      "/" +
                      contextAuthStr +
                      "/" +
                      layout.i
                    : contextWidgetList[layout.i].link.substring(0, 7) ===
                      "MODULE:"
                    ? contextWidgetList[layout.i].link
                    : contextWidgetList[layout.i].link
                }
                child={contextWidgetList[layout.i].child}
              />
            </div>
          );
        })}
      </GridLayout>
    </div>
  );
}
