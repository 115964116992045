import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import { OPEN_WEATHER_API_KEY } from "../utils/env/keys";
import { CURRENT_WEATHER_REQUEST } from "../utils/requests";
import { OPEN_WEATHER_CITY_LIST } from "../utils/env/options";
import moment from "moment-timezone";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "110px",
  },
  subtitle: {
    marginLeft: "5px",
  },
  weatherIcon: { height: "100px", paddingBottom: "10px" },
}));

export default function PlaceHolder({ cityCode }) {
  const [weatherInfo, setWeatherInfo] = useState([]);
  const [visibility, setVisibility] = useState(false);
  const classes = useStyles(visibility);

  useEffect(() => {
    let isSubscribed = true;
    CURRENT_WEATHER_REQUEST(cityCode, OPEN_WEATHER_API_KEY)
      .then((result) => {
        if (isSubscribed) {
          result["cityInfo"] = OPEN_WEATHER_CITY_LIST.find(
            (city) => city.code === cityCode
          );
          console.log(result);
          setWeatherInfo(result);
          setVisibility(true);
        }
      })
      .catch((error) => {
        console.log(error);
      });
    return () => (isSubscribed = false);
  }, [cityCode]);

  return (
    <Grid container spacing={2} className={classes.root}>
      <Grid item>
        <Typography variant="h5">
          {visibility
            ? `${weatherInfo.cityInfo.name}, ${weatherInfo.cityInfo.province}`
            : "City, Province"}
        </Typography>
        <Typography variant="subtitle1" className={classes.subtitle}>
          {visibility
            ? `as of ${moment(weatherInfo.dt * 1000)
                .tz(moment.tz.guess(true))
                .format("HH:MM z")}`
            : "as of 00:00 ---"}
        </Typography>
        <Typography variant="subtitle1" className={classes.subtitle}>
          {visibility
            ? moment(weatherInfo.dt * 1000).format("LL")
            : "-- --, ----"}
        </Typography>
      </Grid>
      <Grid item>
        <Typography align="right" variant="h4">
          {visibility ? `${Math.round(weatherInfo.temp)}℃` : "--"}
        </Typography>
        <Typography align="right" variant="h6">
          {visibility ? weatherInfo.condition : "unknown"}
        </Typography>
      </Grid>
      <Grid item>
        {visibility ? (
          <img
            src={`http://openweathermap.org/img/wn/${weatherInfo.icon}@2x.png`}
            alt={weatherInfo.condition}
            className={classes.weatherIcon}
          />
        ) : null}
      </Grid>
    </Grid>
  );
}
