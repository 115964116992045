import React, { Component } from "react";
import {
  Page,
  Text,
  View,
  Image,
  Document,
  StyleSheet,
} from "@react-pdf/renderer";
import { RenderComments } from "../rendercomments/RenderComments";

// code formatted for pdf downloading and printing
// note that all text must be contained within a "Text" element
export default function RenderForPdf(Item, UserID, ContextToken) {
  if (Item?.length) {
    return (
      <Document>
        {Item.map((item, index) => (
          <Page
            size="LETTER"
            style={{ marginTop: 25, marginLeft: 25, marginRight: 25 }}
          >
            <View
              key={index}
              style={{ display: "flex", flexDirection: "column" }}
            >
              <View
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <View
                  className="mms-report-title-large"
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  <Text Style={{ fontWeight: "bold" }}>
                    Class {item.mms_class}:
                  </Text>
                  <Text>{item.mms_damage_typ}</Text>
                </View>
              </View>
              <View style={{ display: "flex", flexDirection: "row" }}>
                <View
                  style={{
                    width: "50%",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Image
                    style={{ height: "100%", borderRadius: 25, maxHeight: 300 }}
                    src={item.image + ContextToken}
                  />

                  <Text className="comments-header">Comments</Text>
                  {item?.Comments?.reverse().map((comment) => {
                    return (
                      <View
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "top",
                          marginTop: 15,
                        }}
                      >
                        <View>
                          <Text
                            style={{ fontSize: "12pt", fontWeight: "bold" }}
                          >
                            {comment.first_name +
                              " " +
                              comment.last_name +
                              " wrote at " +
                              comment.datetime +
                              ":"}
                          </Text>
                          <Text style={{ fontSize: "14pt" }}>
                            {comment.comment}
                          </Text>
                        </View>
                      </View>
                    );
                  })}
                </View>
                <View
                  style={{
                    width: "50%",
                    display: "flex",
                    flexDirection: "column",
                    padding: 10,
                    fontSize: "12pt",
                  }}
                >
                  <Text>Issue Details:</Text>
                  <Text style={{ margin: 0, marginBottom: 15 }}>
                    {item.date_collected + " at " + item.timestamp}
                  </Text>

                  <Text>Service Window:</Text>
                  <Text style={{ margin: 0, marginBottom: 15 }}>
                    Repair by {item.mms_due_display}
                  </Text>

                  <Text>Location:</Text>
                  <Text style={{ margin: 0, marginBottom: 15 }}>
                    {item.Address}
                  </Text>

                  <Text>GPS:</Text>
                  <Text style={{ margin: 0, marginBottom: 15 }}>
                    {item.long},{item.lat}
                  </Text>

                  <Text>Device:</Text>
                  <Text style={{ margin: 0, marginBottom: 15 }}>
                    {item.device}
                  </Text>

                  <View style={{ flexDirection: "row" }}>
                    <View style={{ width: "50%" }}>
                      <Text>MMS Class:</Text>
                      <Text style={{ margin: 0, marginBottom: 15 }}>
                        {item.mms_class}
                      </Text>
                    </View>

                    <View style={{ width: "50%" }}>
                      <Text>MMS District:</Text>
                      <Text style={{ margin: 0, marginBottom: 15 }}>
                        {item.mms_district}
                      </Text>
                    </View>
                  </View>

                  <View style={{ display: "flex", flexDirection: "row" }}>
                    <View style={{ width: "50%" }}>
                      <Text>Ward:</Text>
                      <Text style={{ margin: 0, marginBottom: 15 }}>
                        {item.ward}
                      </Text>
                    </View>
                    <View style={{ width: "50%" }}>
                      <Text>ID:</Text>
                      <Text style={{ margin: 0, marginBottom: 15 }}>
                        {item.datapoint_id}
                      </Text>
                    </View>
                  </View>
                </View>
              </View>
            </View>
          </Page>
        ))}
      </Document>
    );
  }
  return (
    <Document>
      <Page
        size="LETTER"
        style={{ marginTop: 25, marginLeft: 25, marginRight: 25 }}
      >
        <View style={{ display: "flex", flexDirection: "column" }}>
          <View
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <View
              className="mms-report-title-large"
              style={{ width: "100%", display: "flex", flexDirection: "row" }}
            >
              <Text Style={{ fontWeight: "bold" }}>
                Class {Item.mms_class}:
              </Text>
              <Text>{Item.mms_damage_typ}</Text>
            </View>
          </View>
          <View style={{ display: "flex", flexDirection: "row" }}>
            <View
              style={{ width: "50%", display: "flex", flexDirection: "column" }}
            >
              <Image
                style={{ height: "100%", borderRadius: 25, maxHeight: 300 }}
                src={Item.image + ContextToken}
              />

              <Text className="comments-header">Comments</Text>
              {Item.Comments.reverse().map((comment) => {
                return (
                  <View
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "top",
                      marginTop: 15,
                    }}
                  >
                    <View>
                      <Text style={{ fontSize: "12pt", fontWeight: "bold" }}>
                        {comment.first_name +
                          " " +
                          comment.last_name +
                          " wrote at " +
                          comment.datetime +
                          ":"}
                      </Text>
                      <Text style={{ fontSize: "14pt" }}>
                        {comment.comment}
                      </Text>
                    </View>
                  </View>
                );
              })}
            </View>
            <View
              style={{
                width: "50%",
                display: "flex",
                flexDirection: "column",
                padding: 10,
                fontSize: "12pt",
              }}
            >
              <Text>Issue Details:</Text>
              <Text style={{ margin: 0, marginBottom: 15 }}>
                {Item.date_collected + " at " + Item.timestamp}
              </Text>

              <Text>Service Window:</Text>
              <Text style={{ margin: 0, marginBottom: 15 }}>
                Repair by {Item.mms_due_display}
              </Text>

              <Text>Location:</Text>
              <Text style={{ margin: 0, marginBottom: 15 }}>
                {Item.Address}
              </Text>

              <Text>GPS:</Text>
              <Text style={{ margin: 0, marginBottom: 15 }}>
                {Item.long},{Item.lat}
              </Text>

              <Text>Device:</Text>
              <Text style={{ margin: 0, marginBottom: 15 }}>{Item.device}</Text>

              <View style={{ flexDirection: "row" }}>
                <View style={{ width: "50%" }}>
                  <Text>MMS Class:</Text>
                  <Text style={{ margin: 0, marginBottom: 15 }}>
                    {Item.mms_class}
                  </Text>
                </View>

                <View style={{ width: "50%" }}>
                  <Text>MMS District:</Text>
                  <Text style={{ margin: 0, marginBottom: 15 }}>
                    {Item.mms_district}
                  </Text>
                </View>
              </View>

              <View style={{ display: "flex", flexDirection: "row" }}>
                <View style={{ width: "50%" }}>
                  <Text>Ward:</Text>
                  <Text style={{ margin: 0, marginBottom: 15 }}>
                    {Item.ward}
                  </Text>
                </View>
                <View style={{ width: "50%" }}>
                  <Text>ID:</Text>
                  <Text style={{ margin: 0, marginBottom: 15 }}>
                    {Item.datapoint_id}
                  </Text>
                </View>
              </View>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
}
