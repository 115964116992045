/**
 * IRIS R&D Group Inc. All rights reserved.
 *
 * Author: Lucien Chu
 * Create Date: Jan 12, 2021
 *
 * Description: A component for an administrator who can view, create, udpate
 * and remove a user under the scope of his city.
 */

import React, {
  useState,
  useEffect,
  useContext,
  useCallback,
  useRef,
} from "react";
import withModal from "../../HOC/withModal";
import {
  getCityUsers,
  IRISGO_UPDATE_USER_PROFILE_REQUEST,
} from "../../utils/requests";
import { AuthContext } from "../AuthContext";
import CreateNewUser from "../createNewUser/CreateNewUser";
import UserComponent from "../userComponent/UserComponent";
import IrisModal from "../userPreference/IrisModal";
import UserPreference from "../userPreference/UserPreference";
import styles from "./AdminComponent.module.scss";

import Spinner from "../../irisUI/spinner/Spinner";
import { InputAdornment, TextField } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";

/**
 * @summary flatte complex user objects into single layer object
 *
 * @description user object fetched from server are nested object, for this component and its children,
 * only a few of its properties are taken into account, but they are nexted in different level.
 *
 * @param {Object} users
 * @returns An array with flattened user
 */
const getFlattenedUsers = (users) => {
  const flattedUsers = users.map((user) => {
    const { username, email, groups, id, profile } = user;

    let cityWidgets = [];
    let disabled_widgets = [];
    let enabled = false;
    let settings = {};
    if (profile && profile.city && profile.city.widgets) {
      cityWidgets = profile.city.widgets;
    }
    if (profile && profile.disabled_widgets) {
      disabled_widgets = profile.disabled_widgets;
    }

    if (profile && profile.enabled) {
      if (profile.enabled === "Y") {
        enabled = true;
      }
    }

    if (
      profile &&
      profile.settings &&
      profile.settings.settings &&
      profile.settings.settings.open_weather_city_id
    ) {
      settings = {
        open_weather_city_id: profile.settings.settings.open_weather_city_id,
      };
    }

    return {
      username,
      email,
      groups,
      id,
      disabled_widgets,
      enabled,
      cityWidgets,
      settings,
    };
  });

  // const enabledUsers = flattedUsers.filter((user) => user.enabled === true);
  // debugger;
  return flattedUsers;
};

// sort arry of object based on one property's string value alphabetically
const sortUsersByName = (arr, property) => {
  const copied = [...arr]; // shallow copied, if it is nested object, would be a problem
  copied.sort((objectA, objectB) => {
    const valueA = objectA[property].toUpperCase(); // ignore upper and lowercase
    const valueB = objectB[property].toUpperCase(); // ignore upper and lowercase
    if (valueA < valueB) {
      return -1;
    }
    if (valueA > valueB) {
      return 1;
    }

    return 0; // names must be equal
  });
  return copied;
};

function AdminComponent(props) {
  const authContext = useContext(AuthContext);
  const { contextCity, contextToken } = authContext;
  const [users, setUsers] = useState([]);
  const [isCreateUser, setIsCreateUser] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  // reference that keys an array of all users, whehter enabled or not
  const allUsersRef = useRef([]);

  const handleUpdateUsers = (updatedUser) => {
    setSelectedUser(null); // close modal
    if (updatedUser) {
      const { settings, id, disabled_widgets, enabled } = updatedUser;
      IRISGO_UPDATE_USER_PROFILE_REQUEST(
        id,
        { settings, disabled_widgets, enabled },
        contextToken
      )
        .then((result) => {
          if (result.success === true) {
            let updatedUsers;
            if (enabled === "N") {
              props.openModal("Success", `User is disabled`);
              updatedUsers = users.filter((user) => user.id !== id);
            } else {
              props.openModal(
                "Success",
                `User profile is updated successfully`
              );
              updatedUsers = users.map((user) => {
                if (updatedUser.id === user.id) {
                  return { ...updatedUser, enabled: true };
                }
                return user;
              });
            }
            setUsers(updatedUsers);
          }
        })
        .catch((error) => {
          console.log(error);
          props.openModal("Upate user error", `Error in updating user profile`);
        });
    }
  };

  /**
   * @summary a callback function passed as value of to CreateNewUser component's onHandleCreateUser property
   *
   * @description this function would be called by CreateNewUser whenever a new user is created successfully
   *
   * @param {Object} user
   */
  const handleCreateUser = (user) => {
    if (user !== null && user !== undefined) {
      // refresh user by calling server
      fetchUsers();
      props.openModal(
        "Success",
        `user ${user.userName} is created successfully.`
      );
    }
    setIsCreateUser(false);
  };

  /**
   * get users when component is mounted or
   * a new user is created
   */
  const fetchUsers = useCallback(() => {
    setIsLoading(true);
    getCityUsers(contextToken, contextCity.id)
      .then((result) => {
        const flattenedUsers = getFlattenedUsers(result);
        const sortedUsers = sortUsersByName(flattenedUsers, "username");
        allUsersRef.current = [...sortedUsers];
        debugger;
        setUsers(sortedUsers);
      })
      .catch((error) => {
        props.openModal("Error", `Error in updating user profile: ${error}`);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  useEffect(() => {
    fetchUsers();
  }, [fetchUsers, setUsers]);

  /**
   *
   * @summary a callback function passed as value of onChange property.
   *
   * @description as user typing on the textfied, filter users who contains the
   * input values as their user name.
   *
   * @param {Event} event
   */
  const handleSearchUser = (event) => {
    const value = event.target.value.toLowerCase();
    let filterUsers;
    if (value === "") {
      filterUsers = [...allUsersRef.current];
    } else {
      filterUsers = allUsersRef.current.filter((user) =>
        user.username.toLowerCase().includes(value)
      );
    }
    debugger;
    setUsers(filterUsers);
  };

  return (
    <div className={styles.container}>
      <div className={styles.fixedSearchTextField}>
        <TextField
          autoFocus
          placeholder="Search by user name"
          InputProps={{
            startAdornment: (
              <InputAdornment>
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          onChange={handleSearchUser}
        ></TextField>
      </div>

      {/* <div className={styles.userGridContainer}> */}
      <div className={styles.userGrid}>
        {isLoading && <Spinner />}
        <IrisModal open={selectedUser} onClose={() => setSelectedUser(null)}>
          <UserPreference
            user={selectedUser}
            handleUpdateUsers={handleUpdateUsers}
          />
        </IrisModal>
        <IrisModal open={isCreateUser} onClose={() => setIsCreateUser(false)}>
          <CreateNewUser
            openModal={props.openModal}
            // pass all user name regardless whehter user is enabled or not
            existedUsers={allUsersRef.current.map((user) =>
              user.username.toLowerCase()
            )}
            widgetList={contextCity.widgets}
            handleCreateUser={handleCreateUser}
          />
        </IrisModal>

        {/* only render the users who are enabled */}
        {users
          .filter((user) => user.enabled)
          .map((user, index) => {
            return (
              <UserComponent
                disabled={user.enabled && user.enabled === false}
                key={index}
                userName={user.username}
                onClick={() => setSelectedUser(user)}
              />
            );
          })}
        <UserComponent onClick={() => setIsCreateUser(true)} />
      </div>
      {/* </div> */}
    </div>
  );
}

export default withModal(AdminComponent);

/**
 * Change Log:
 *
 * Change Date: Jan 25, 2021
 *
 * Description: component is created and documentation is added.
 */

/**
 * Change Log:
 *
 * Change Date: Jan 25, 2021
 *
 * Description:
 *
 * added feature
 *   1. add textfield for search users
 *
 * updated UI
 *   1. added textfied is set to posistion fixed
 *      so that it would stay at the same position while administrator is scrolling the
 *      user grid component.
 */
