import React from "react";
// import makeStyles from "@material-ui/core/styles/makeStyles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Toolbar from "@material-ui/core/Toolbar";
import DashBoard from "./Dashboard";
import { Route, Switch } from "react-router-dom";
import Header from "./Header";
import AdminComponent from "./adminComponent/AdminComponent";
import IrisHeader, {
  DropdownNavigation,
  HeaderLink,
} from "./irisHeader/IrisHeader";
import irisCityLogo from "../assets/IrisCityLogo.png";
import { Button } from "@material-ui/core";
// const useStyles = makeStyles((theme) => ({}));

export default function AdminConsole(props) {
  // const classes = useStyles();

  return (
    <div>
      <CssBaseline />
      <Header {...props} />
      {/* <IrisHeader logo={irisCityLogo} {...props}>
        <DropdownNavigation />
        <HeaderLink>
          <Button variant="outlined" color="primary">
            About
          </Button>
        </HeaderLink>
      </IrisHeader> */}
      <Toolbar />
      <Switch>
        <Route exact path="/" component={DashBoard} />
        {/* <Route exact path="/admin" component={AdminComponent} /> */}
      </Switch>
    </div>
  );
}
