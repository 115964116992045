import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import { TextField } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    // height: "530px",
    width: "50%",
    maxHeight: "calc(100% - 64px)",
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    display: "flex",
    flexDirection: "column",
  },
  settingRow: {
    justifyContent: "space-between",
    alignItems: "center",
  },
  saveButton: {
    marginTop: "30px",
  },
  weatherOptions: {
    width: "215px",
  },
}));

export default function UserPreference(props) {
  const classes = useStyles();
  const { user, handleUpdateUsers: onHandleUpdateUsers } = props;
  const [moduleList, setModuleList] = useState([]);
  const [currentUser, setCurrentUser] = useState({ ...user });

  console.log("user", user);
  // const onSave = () => {
  //   const updatedUser = { ...currentUser };
  //   let layout = [];
  //   for (const module of moduleList) {
  //     if (module.enabled === true) {
  //       if (user.profile.settings["iriscity_dashboard_layout"]) {
  //         let enabledModule = user.profile.settings[
  //           "iriscity_dashboard_layout"
  //         ].find((e) => e.i === module.key);
  //         debugger;
  //         if (enabledModule) {
  //           layout.push(enabledModule);
  //         } else {
  //           layout.push({
  //             h: 6,
  //             i: module.key,
  //             w: 6,
  //             x: 99,
  //             y: 99,
  //             minW: 6,
  //             moved: false,
  //             static: false,
  //           });
  //         }
  //       } else {
  //         layout.push({
  //           h: 6,
  //           i: module.key,
  //           w: 6,
  //           x: 99,
  //           y: 99,
  //           minW: 6,
  //           moved: false,
  //           static: false,
  //         });
  //       }
  //     }
  //   }
  //   updatedUser.profile.settings["iriscity_dashboard_layout"] = layout;
  //   debugger;
  //   onHandleUpdateUsers(updatedUser);
  // };
  // const onSave = () => {
  //   // 1. send update to server

  //   // 2. with proper response
  //   const updatedUser = { ...currentUser };
  //   debugger;
  //   updatedUser.profile.settings.iriscity_dashboard_layout = [...moduleList];
  //   onHandleUpdateUsers(updatedUser);
  // };

  const onSave = () => {
    const { enabled, id } = currentUser;
    const disabledWidgetIds = [];
    for (const module of moduleList) {
      const { id, enabled } = module;
      if (enabled === false) {
        disabledWidgetIds.push(id);
      }
    }
    currentUser.id = id;
    currentUser.disabled_widgets = disabledWidgetIds;
    currentUser.enabled = enabled === true ? "Y" : "N";
    onHandleUpdateUsers(currentUser);
  };
  const onDisabled = () => {
    // const updatedUser = { ...currentUser };
    // updatedUser.enabled = !updatedUser.enabled;
    // setCurrentUser(updatedUser);
    setCurrentUser((currentUser) => ({
      ...currentUser,
      enabled: !currentUser.enabled,
    }));
  };

  const onCancel = () => {
    onHandleUpdateUsers();
  };

  const toggleModule = (event, index, moduleKey) => {
    const checked = event.target.checked;
    const updatedModuleList = [...moduleList];
    updatedModuleList[index].enabled = checked;
    setModuleList(updatedModuleList);
  };

  // useEffect(() => {
  //   if (user === null || user === undefined) {
  //     return;
  //   }
  //   if (
  //     user &&
  //     user.profile &&
  //     user.profile.settings &&
  //     user.profile.settings.iriscity_dashboard_layout
  //   ) {
  //     debugger;
  //     const dashboardKeys = user.profile.settings.iriscity_dashboard_layout.reduce(
  //       (acc, ele) => {
  //         return [...acc, ele.i];
  //       },
  //       []
  //     );
  //     const widgets = user.profile.city.widgets;

  //     const moduleList = widgets.map((widget) => {
  //       const widgetKey = widget.key;
  //       if (dashboardKeys.indexOf(widgetKey) >= 0) {
  //         return { ...widget, enabled: true };
  //       }
  //       return { ...widget, enabled: false };
  //     });
  //     setModuleList(moduleList);
  //   } else {
  //     const moduleList = user.profile.city.widgets.map((widget) => {
  //       return { ...widget, enabled: true };
  //     });
  //     setModuleList(moduleList);
  //   }
  // }, [user]);

  useEffect(() => {
    if (user === null || user === undefined) {
      return;
    }

    const disabledWidgetIds = user.disabled_widgets;
    const widgets = user.cityWidgets;

    const moduleList = widgets.map((widget) => {
      const shouldDisabled = disabledWidgetIds.indexOf(widget.id) > -1;
      return { ...widget, enabled: !shouldDisabled };
    });
    setModuleList(moduleList);
  }, [user]);

  return user !== null && user !== undefined ? (
    <div className={classes.root}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <h2
          style={{
            fontSize: "1.25rem",
            fontWeight: 500,
            lineHeight: 1.6,
            letterSpacing: "0.0075em",
          }}
        >
          {/* user name: {user.username} <br />
            user email: {user.email} */}
          User UserPreference
        </h2>
        <Button
          onClick={onDisabled}
          variant="outlined"
          color="secondary"
          size="small"
        >
          {currentUser.enabled ? "Disable" : "Enable"}
        </Button>
      </div>
      <DialogContent dividers>
        <TextField
          fullWidth
          value={user.username}
          InputProps={{
            readOnly: true,
            disabled: true,
          }}
        />
        <TextField
          fullWidth
          value={user.email}
          InputProps={{
            readOnly: true,
            disabled: true,
          }}
        />
        {moduleList.map((module, index) => (
          <Grid container key={index} className={classes.settingRow}>
            <Grid item>
              <Typography variant="subtitle2">
                {module.title}
                {console.log("module", module)}
              </Typography>
            </Grid>
            <Grid item>
              <FormControlLabel
                control={
                  <Checkbox
                    icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                    checkedIcon={<CheckBoxIcon fontSize="small" />}
                    checked={module.enabled}
                    disabled={
                      currentUser.enabled !== undefined && !currentUser.enabled
                    }
                    onChange={(event) => toggleModule(event, index)}
                    color="primary"
                  />
                }
                label={<Typography variant="subtitle2">Enable</Typography>}
              />
            </Grid>
          </Grid>
        ))}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={onCancel}
          variant="outlined"
          color="secondary"
          className={classes.saveButton}
        >
          Cancel
        </Button>
        <Button
          onClick={onSave}
          variant="contained"
          color="primary"
          className={classes.saveButton}
        >
          Save
        </Button>
      </DialogActions>
    </div>
  ) : (
    <div></div>
  );
}
