import React, { useEffect } from "react";
import Homepage from "./components/Homepage";
import Login from "./components/Login";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import PrivateRoute from "./components/PrivateRoute";
import { AuthContextProvider } from "./components/AuthContext";
import NotFound from "./components/NotFound";
import { MainContextProvider } from "./components/MainContext";
import IRISThemeProvider from "./utils/theme/theme";
import AdminComponent from "./components/adminComponent/AdminComponent";
import RequestAccountForm from "./components/requestAccountForm/RequestAccountForm";
import Module from "./modules/Module";

function App() {
  return (
    <IRISThemeProvider>
      <AuthContextProvider>
        <MainContextProvider>
          <Router>
            <Switch>
              <PrivateRoute exact path="/" component={Homepage} />
              <PrivateRoute exact path="/admin" component={AdminComponent} />
              <Route exact path="/module/:module" component={Module} />
              <Route exact path="/login" component={Login} />
              {/* <Route exact path="/login" component={RequestAccountForm} /> */}
              <Route
                exact
                path="/module/:module/:auth/:moduleName"
                component={Login}
              />
              {/* <Route exact path="/login" component={AdminComponent} /> */}
              <Route exact component={NotFound} />
            </Switch>
          </Router>
        </MainContextProvider>
      </AuthContextProvider>
    </IRISThemeProvider>
  );
}

export default App;
