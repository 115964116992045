/**
 * IRIS R&D Group Inc. All rights reserved.
 *
 * Author: Lucien Chu
 * Create Date: Jan 15, 2021
 *
 * Description: A component enabled to user to reset his password
 */

import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { FormHelperText } from "@material-ui/core";

const MINIMUM_PASSWORD_LENGTH = 8;
export default function ResetPassword(props) {
  const { isOpen, userName } = props;
  const { onClose, onSubmit } = props;
  const [open, setOpen] = React.useState(false);
  const [newPassword, setNewPassword] = useState("");
  const [confirmedPassword, setConfirmedPassword] = useState("");
  const [helperTextProperties, setHelperTextProperties] = useState({
    hasError: false,
    textContent: "",
  });

  const handleCancel = () => {
    setNewPassword("");
    setConfirmedPassword("");
    onClose();
  };

  const handleConfirmPasswordKeyUp = (event) => {
    if (event.keyCode === 13) {
      handleSubmission();
    }
  };

  const handleSubmission = () => {
    if (
      newPassword.length >= MINIMUM_PASSWORD_LENGTH &&
      newPassword === confirmedPassword
    ) {
      const submittedPassword = newPassword;
      setNewPassword("");
      setConfirmedPassword("");
      onSubmit(submittedPassword);
      onClose();
    }
  };

  useEffect(() => {
    setOpen(isOpen);
  }, [isOpen]);

  useEffect(() => {
    let helperText = "";
    let hasError = false;
    if (newPassword.length === 0) {
      helperText = "Please type your new password";
      hasError = false;
    } else if (newPassword.length < MINIMUM_PASSWORD_LENGTH) {
      helperText = `Password must contain at least ${MINIMUM_PASSWORD_LENGTH} characters.`;
      hasError = true;
    } else if (confirmedPassword.length === 0) {
      helperText = `Please confirm the new password.`;
      hasError = false;
    } else if (newPassword !== confirmedPassword) {
      helperText = `Two password are not identical`;
      hasError = true;
    } else {
      helperText = `Please click 'Submit' button`;
      hasError = false;
    }
    setHelperTextProperties({
      hasError: hasError,
      textContent: helperText,
    });
  }, [newPassword, confirmedPassword, setHelperTextProperties]);
  return (
    <Dialog open={open} aria-labelledby="reset-password-title">
      <DialogTitle id="reset-password-title">Welcome {userName}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Please reset you password before you first login
        </DialogContentText>
        <TextField
          autoFocus
          id="newPassword"
          label="New password"
          type="password"
          fullWidth
          value={newPassword}
          onChange={(event) => setNewPassword(event.target.value)}
        />
        <div style={{ margin: "1rem 0" }}></div>
        <TextField
          id="confirmNewPassword"
          label="Confirm password"
          type="password"
          fullWidth
          value={confirmedPassword}
          onChange={(event) => setConfirmedPassword(event.target.value)}
          InputProps={{
            onKeyUp: handleConfirmPasswordKeyUp,
          }}
        />
        <FormHelperText error={helperTextProperties.hasError}>
          {helperTextProperties.textContent}
        </FormHelperText>
      </DialogContent>
      <DialogActions>
        <Button color="secondary" onClick={handleCancel}>
          Cancel
        </Button>
        <Button
          color="primary"
          onClick={handleSubmission}
          disabled={
            !(
              newPassword.length >= MINIMUM_PASSWORD_LENGTH &&
              newPassword === confirmedPassword
            )
          }
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
}
