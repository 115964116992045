import React from "react";
import styles from "./UserComponent.module.scss";
import addAccountImage from "./add_account.png";
import accountImage from "./account_image.png";
import EditIcon from "@material-ui/icons/Edit";
export default function UserComponent(props) {
  const { userName, disabled, onClick } = props;
  return (
    <div
      className={`${styles.container} ${disabled ? styles.disabled : ""}`}
      onClick={onClick}
    >
      {userName ? (
        <>
          <div className={styles.editIcon}>
            <EditIcon fontSize="small" />
          </div>
          <div
            className={`${styles.letterCircle} ${
              disabled ? styles.disabled : ""
            }`}
          >
            {userName[0].toUpperCase()}
          </div>
          <span>{userName}</span>
        </>
      ) : (
        <>
          <img className={styles.img} src={addAccountImage} alt="image" />
        </>
      )}
    </div>
  );
}
