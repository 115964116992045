import {
  Page,
  Text,
  View,
  Image,
  Document,
  StyleSheet,
} from "@react-pdf/renderer";
import { pdf } from "@react-pdf/renderer";
import ReactPDF from "@react-pdf/renderer";
import PDFViewer from "@react-pdf/renderer";

// select all elements in the passed element
export const copyElementToClipboard = async (element) => {
  var doc = document,
    text = element,
    range,
    selection;
  if (doc.body.createTextRange) {
    //ms
    range = doc.body.createTextRange();
    range.moveToElementText(text);
    range.select();
  } else if (window.getSelection) {
    //all others
    selection = window.getSelection();
    range = doc.createRange();
    range.selectNodeContents(text);
    selection.removeAllRanges();
    selection.addRange(range);
  }
  await document.execCommand("copy");
  if (window.getSelection) {
    window.getSelection().removeAllRanges();
  } else if (document.selection) {
    document.selection.empty();
  }

  alert("Contents have been copied to the clipboard");
};

// trigger a data blob to be saved to the desktop
export const saveBlob = async (blob, filename) => {
  var a = document.createElement("a");
  document.body.appendChild(a);
  a.style.display = "none";
  let url = window.URL.createObjectURL(blob);
  a.href = url;
  a.download = filename;
  a.click();
  window.URL.revokeObjectURL(url);
};

// trigger a data blob to be printed
export const printBlob = async (blob, filename) => {
  var a = document.createElement("a");
  var b = document.createElement("iframe");
  b.width = 1;
  b.height = 1;
  b.name = "printiframe";
  document.body.appendChild(a);
  document.body.appendChild(b);
  a.style.display = "none";
  let url = window.URL.createObjectURL(blob);
  a.href = url;
  a.target = "printiframe";
  a.click();
  b.addEventListener("load", function () {
    b.focus();
    b.contentWindow.print();
  });
  window.URL.revokeObjectURL(url);
};

// save selected item as pdf
export const savePdf = async (document, filename) => {
  pdf(document)
    .toBlob()
    .then((data) => {
      saveBlob(data, filename);
    });
  // saveBlob(await pdf(document).toBlob(), filename);
};

// print selected item as pdf
export const printPdf = async (document) => {
  printBlob(await pdf(document).toBlob(), "print.pdf");
};
