import React, { useState, useEffect, useContext } from "react";
import { AuthContext } from "../components/AuthContext";
import makeStyles from "@material-ui/core/styles/makeStyles";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Hidden from "@material-ui/core/Hidden";
import welcomeImg from "../assets/WelcomeIMG.jpg";
import IrisCityLogo from "../assets/IrisCityLogo.png";
import irisLogo from "../assets/irisLogo.png";
import { irisLookup, LOGIN_REQUEST, resetPassword } from "../utils/requests";
import { FILTER_CITY_LAYOUT } from "../utils/utils";
import { DEFAULT_LAYOUT } from "../utils/env/options";
import ResetPassword from "./restPasswordComponent/ResetPassword";
import withModal from "../HOC/withModal";
import { USER_NAME } from "../utils/env/localStorageKeys";

import esriLogo from "../assets/esriLogo.png";
import geotabLogo from "../assets/geotabLogo.jpg";
import IrisModal from "./userPreference/IrisModal";
import RequestAccountForm from "./requestAccountForm/RequestAccountForm";
import TermsOfUse from "./termsOfUser/TermsOfUse";

const PASSWORD_MIN_LENGTH = 6;
const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
  },
  loginPanel: {
    position: "relative",
    padding: "10px",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    // overflow: "auto",
  },

  logoAndTitles: {},
  loginTextFieldsAndButton: {
    margin: "2rem 0",
    flexGrow: 1,
    // "@media (max-width:(960px)": {
    //   margin: 0,
    // },
    "@media screen and (max-width: 960px)": {
      margin: 0,
    },
  },
  loginFormFooter: {
    "& a": {
      textDecoration: "none",
      margin: "0.375rem 0",
      color: "currentColor",
      fontSize: "1.15rem",
      fontWeight: 500,
      "&:hover": {
        color: "#c1151f",
      },
    },
  },
  loginForm: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    margin: "auto 0",
    width: "55%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    "& > *": {
      textAlign: "center",
      width: "100%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      "& > *": {
        width: "100%",
      },
    },
  },

  loginFooter: {
    maxHeight: "20vh",
    position: "absolute",
    bottom: 0,
    textAlign: "center",
  },
  welcomeImg: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
  },
  irisLogo: {
    position: "absolute",
    width: "120px",
    top: "1.5%",
    right: "1.5%",
  },
  loginLogo: {
    objectFit: "contain",
    width: "70%",
  },
  imagePanel: {
    position: "relative",
  },

  inputText: {
    // margin: "0.375rem 0",
    fontSize: "1.15rem",
    textAlign: "center",
  },

  loginButton: {
    border: "none",
    marginTop: "0.375rem",
    padding: "0.375rem",
    borderRadius: "3px",
    fontWeight: "bold",
    fontSize: "1.15rem",
    backgroundColor: "#ffffff",
    "&:focus": {
      outline: "none",
    },

    "&:enabled": {
      backgroundColor: "#c1151f",
      color: "#ffffff",
    },
    "&:hover": {
      cursor: "pointer",
      "&:enabled": {
        backgroundColor: "#A80006",
        // color: "#ffffff",
      },
    },
  },

  createAccountButton: {
    border: "none",
    marginTop: "0.375rem",
    padding: "0.375rem",
    borderRadius: "3px",
    fontWeight: 600,
    fontSize: "1.15rem",
    backgroundColor: "#ffffff",
    "&:focus": {
      outline: "none",
    },
    "&:hover": {
      cursor: "pointer",
      color: "#c1151f",
    },
  },

  cooperatorLogo: {
    width: "80%",
    // "@media screen and (max-width: 960px)": {
    //   width: "80%",
    // },
    "@media screen and (max-width: 600px)": {
      width: "40%",
    },
  },
}));

function Login(props) {
  const { history } = props;
  const { openModal } = props;
  const classes = useStyles();

  const [username, setUsername] = useState(
    localStorage.getItem(USER_NAME) || ""
  );
  const [password, setPassword] = useState("");
  const [userProfileId, setUserProfileId] = useState(-1);
  const [token, setToken] = useState("");

  // state(s) for request new account form
  const [isShowRequestAccountForm, setIsShowRequestAccountForm] = useState(
    false
  );

  // state(s) for terms of use popup
  const [isShowTermsOfUse, setIsShowTermsOfUse] = useState(false);
  const {
    setContextUsername,
    setContextUserID,
    setContextToken,
    setContextGroupInfo,
    setContextPreference,
    setContextCity,
    setContextWidgetList,
    setContextAuthStr,
  } = useContext(AuthContext);

  const [isResetPassword, setIsResetPassword] = useState(false);
  const [shouldDisableLogin, setShouldDisableLogin] = useState(true);

  const handleLogin = async () => {
    // do nothing if password is too short
    // this prevent login process is executed when user press "Enter" on the pass world field
    if (shouldDisableLogin && !props.match.params.auth) {
      return;
    }

    try {
      var token,
        id,
        group,
        preference,
        city,
        disabled_widgets,
        reset,
        user_name,
        first_name,
        last_name;

      if (props.match.params.auth) {
        // this should only happen with a module
        let login_var_string = props.match.params.auth;
        let module_name = props.match.params.moduleName;

        login_var_string = login_var_string.replaceAll("^", "/");
        login_var_string = Buffer.from(login_var_string, "base64").toString(
          "ascii"
        );

        let login_var = JSON.parse(login_var_string);
        ({
          token,
          id,
          group,
          preference,
          city,
          disabled_widgets,
          reset,
          first_name,
          last_name,
          user_name,
        } = login_var);

        let authstr = {
          token: login_var.token,
          id: login_var.id,
          group: login_var.group,
          preference: login_var.preference,
          city: login_var.city,
          disabled_widgets: login_var.disabled_widgets,
          reset: login_var.reset,
          user_name: login_var.user_name,
          first_name: login_var.first_name,
          last_name: login_var.last_name,
          module_name: module_name,
        };
        setContextAuthStr(authstr);
      } else {
        user_name = username;
        ({
          token,
          id,
          group,
          preference,
          city,
          disabled_widgets,
          reset,
          first_name,
          last_name,
        } = await LOGIN_REQUEST(username, password));

        let login_vars = {
          token,
          id,
          group,
          preference,
          city,
          disabled_widgets,
          reset,
          first_name,
          last_name,
          user_name,
        };
        let login_vars_str = JSON.stringify(login_vars);
        login_vars_str = Buffer.from(login_vars_str).toString("base64");
        login_vars_str = login_vars_str.replaceAll("/", "^");
        setContextAuthStr(login_vars_str);
      }

      if (reset && reset === "Y") {
        const lookupResult = await irisLookup(token, ["user"]);
        debugger;
        const foundUser = lookupResult.result.user.find(
          (user) => user.id === id
        );
        if (foundUser === null || foundUser === undefined) {
          throw new Error("User profile not found");
        }
        debugger;
        setPassword("");
        setUserProfileId(foundUser.profile.id);
        setToken(token);
        setIsResetPassword(true);
        window.gtag(
          "event",
          `${foundUser.profile.city.name}_${foundUser.username}_reset_password`
        );
      } else {
        let {
          defaultTotalLayout,
          preferenceWithFilteredLayout,
        } = FILTER_CITY_LAYOUT(
          preference,
          city.widgets,
          DEFAULT_LAYOUT,
          disabled_widgets
        );
        setContextUsername(user_name);
        setContextUserID(id);
        setContextToken(token);
        setContextGroupInfo(group);
        setContextPreference(preferenceWithFilteredLayout);
        setContextCity(city);
        setContextWidgetList(defaultTotalLayout);
        localStorage.setItem(USER_NAME, user_name);

        if (props.match.params.auth && props.match.params.module) {
          history.push("/module/" + props.match.params.module);
        } else {
          history.push("/");
        }
      }
    } catch (error) {
      console.log(error);
      openModal("Login Error", error);
    }
    // setIsResetPassword(true);
  };

  const onGetNewPassword = (password) => {
    // call reset password
    resetPassword({ profile_id: userProfileId, new_password: password }, token)
      .then((result) => {
        openModal(
          "Login",
          "Password is reset successfully, please login again"
        );
      })
      .catch((error) => {
        openModal(
          "Error",
          `Error in reset password, please try later.\nError: ${error.message}`
        );
      })
      .finally(() => {
        setUserProfileId(-1);
        setToken("");
        setPassword("");
      });
  };

  useEffect(() => {
    if (props.match.params.auth) {
      handleLogin();
    }
  });

  useEffect(() => {
    const result = !(
      username.trim().length !== 0 &&
      password.trim().length >= PASSWORD_MIN_LENGTH
    );
    setShouldDisableLogin(result);
  }, [username, password, setShouldDisableLogin]);

  const onRequestFormSend = (isSuccess, errorMessage) => {
    // dismiss form modal
    setIsShowRequestAccountForm(false);

    // show informative message
    if (isSuccess) {
      // setIsShowRequestAccountForm(false);
      openModal(
        "Thanks for your interest!",
        "Your request has been sent successfully, we would come back to you shortly."
      );
    } else if (errorMessage) {
      openModal(
        "Please try later",
        "Something goes wrong, your request was not sent. " + errorMessage
      );
    }
    window.gtag("event", "sign up");
  };

  return (
    <Grid container className={classes.root}>
      <ResetPassword
        isOpen={isResetPassword}
        userName={username}
        onClose={() => setIsResetPassword(false)}
        onSubmit={onGetNewPassword}
      />
      <IrisModal
        open={isShowRequestAccountForm}
        onClose={() => setIsShowRequestAccountForm(false)}
      >
        <RequestAccountForm onSubmit={onRequestFormSend} />
      </IrisModal>
      <IrisModal
        open={isShowTermsOfUse}
        onClose={() => setIsShowTermsOfUse(false)}
      >
        <TermsOfUse />
      </IrisModal>
      <Grid container item sm={6} className={classes.loginPanel}>
        <div className={classes.loginForm}>
          <div className={classes.logoAndTitles}>
            <img
              src={IrisCityLogo}
              className={classes.loginLogo}
              alt="irisCITY™"
            />
            <Typography variant="body1">AI Powered GIS Dashboard</Typography>
            <Typography variant="body2">Version 1.3</Typography>
          </div>
          <div className={classes.loginTextFieldsAndButton}>
            <TextField
              autoFocus
              value={username}
              margin="normal"
              placeholder="user name"
              // label="Username"
              onChange={(event) => setUsername(event.target.value)}
              InputProps={{
                // startAdornment: (
                //   <InputAdornment>
                //     <AccountCircle />
                //   </InputAdornment>
                // ),
                classes: {
                  input: classes.inputText,
                },
              }}
            ></TextField>
            <TextField
              margin="normal"
              // label="Password"
              type="password"
              placeholder="password"
              onChange={(event) => setPassword(event.target.value)}
              value={password}
              InputProps={{
                // startAdornment: (
                //   <InputAdornment>
                //     <LockRounded />
                //   </InputAdornment>
                // ),
                classes: {
                  input: classes.inputText,
                },
              }}
              onKeyUp={(e) => {
                if (e.key === "Enter") {
                  handleLogin();
                }
              }}
            ></TextField>

            {/* <Button
              variant="contained"
              disableElevation
              color="primary"
              onClick={handleLogin}
              disabled={
                !(
                  username.trim().length !== 0 &&
                  password.trim().length >= PASSWORD_MIN_LENGTH
                )
              }
            >
              Log in
            </Button> */}
            <button
              onClick={handleLogin}
              disabled={
                !(
                  username.trim().length !== 0 &&
                  password.trim().length >= PASSWORD_MIN_LENGTH
                )
              }
              className={classes.loginButton}
            >
              Login
            </button>
          </div>

          <div className={classes.loginFormFooter}>
            <a href="#" onClick={() => setIsShowRequestAccountForm(true)}>
              Create Account
            </a>
            <a href="https://www.irisradgroup.com/" target="_blank">
              About Us
            </a>
            <a href="#" onClick={() => setIsShowTermsOfUse(true)}>
              Terms of use
            </a>
          </div>
        </div>
        {/* <div className = {classes.loginPanelFooter}>

          </div> */}
        <Grid
          container
          justify="center"
          alignItems="center"
          className={classes.loginFooter}
        >
          <Grid item md={4} sm={7}>
            <a href="https://www.esri.com" target="_blank">
              <img
                className={classes.cooperatorLogo}
                src={esriLogo}
                alt="esri home page"
                // style={{ width: "80%" }}
              />
            </a>
          </Grid>
          <Grid item md={4} sm={7}>
            <a href="https://www.geotab.com/" target="_blank">
              <img
                className={classes.cooperatorLogo}
                src={geotabLogo}
                alt="geotab home page"
                // style={{ maxWidth: "80%" }}
              />
            </a>
          </Grid>
        </Grid>
      </Grid>
      <Hidden xsDown>
        <Grid
          container
          item
          sm={6}
          alignItems="center"
          direction="column"
          className={classes.imagePanel}
        >
          <img src={irisLogo} className={classes.irisLogo} alt="IRIS" />
          <img
            src={welcomeImg}
            className={classes.welcomeImg}
            alt="Welcome to IrisGo Admin Console"
          />
        </Grid>
      </Hidden>
    </Grid>
  );
}
export default withModal(Login);
