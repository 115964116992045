import React, { Component } from "react";

import { updateLabelResolution } from "../../../../utils/requests";
import { AuthContext } from "../../../../components/AuthContext";
import withModal from "../../../../HOC/withModal";

import share_icon from "../../../shared_assets/share.png";
import print_icon from "../../../shared_assets/print.png";
import download_icon from "../../../shared_assets/download.png";
import "../../London_DailyAlerts.css";

class DailyAlert extends Component {
  static contextType = AuthContext;

  constructor() {
    super();

    this.resolution = [
      { label: "EM", title: "Open" },
      { label: "IG", title: "Ignore" },
      { label: "REV", title: "Resolved" },
    ];

    this.state = {
      showMenu: false,
      currentMenu: this.resolution[0].title,
    };

    this.showMenu = this.showMenu.bind(this);
    this.closeMenu = this.closeMenu.bind(this);
  }

  componentDidMount() {
    // get authorization context variables
    const {
      contextUserID,
      contextToken,
      contextPreference,
      contextCity,
      setContextPreference,
      contextWidgetList,
      contextAuthStr,
    } = this.context;

    this.contextUserID = contextUserID;
    this.contextToken = contextToken;
    this.contextPreference = contextPreference;
    this.contextCity = contextCity;
    this.setContextPreference = setContextPreference;
    this.contextWidgetList = contextWidgetList;
    this.contextAuthStr = contextAuthStr;

    // default resolution is 0 (open)
    var init_resolution = 0;

    // find the resolution in the list
    init_resolution = this.resolution.findIndex(
      (item) => item.label === this.props.Data.Resolution
    );

    // set the resolution in the object (from parent)
    this.props.Data.Resolution = this.props.Data.Resolution;

    // update the current resolution menu button
    this.setState({ currentMenu: this.resolution[init_resolution].title });
  }

  // show resolution menu
  showMenu(event) {
    event.preventDefault();
    event.nativeEvent.stopImmediatePropagation();
    this.setState({ showMenu: true }, () => {
      document.addEventListener("click", this.closeMenu);
    });
  }

  // hide resolution menu
  closeMenu(event) {
    if (this.dropdownMenu) {
      if (!this.dropdownMenu.contains(event.target)) {
        this.setState({ showMenu: false }, () => {
          document.removeEventListener("click", this.closeMenu);
        });
      }
    }
  }

  // Updates the label resolution -> in the menu, in the Data object, and in the database
  setMenu(event, title, resolution) {
    event.preventDefault();
    event.nativeEvent.stopImmediatePropagation();
    updateLabelResolution(
      this.props.Data.label_id,
      this.contextToken,
      resolution
    )
      .then((result) => {
        this.props.Data.Resolution = resolution;
        this.setState({ currentMenu: title });
        this.props.ForceUpdate();
      })
      .catch((error) => {
        this.props.openModal("Error", `Error updating resolution: ${error}`);
      })
      .finally(() => {});

    this.setState({ showMenu: false, currentMenu: title }, () => {
      document.removeEventListener("click", this.closeMenu);
    });
  }

  // render the issue
  render() {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          backgroundColor:
            this.props.Data.Resolution == "IG"
              ? "#ffcccc"
              : this.props.Data.Resolution == "REV"
              ? "#ccffcc"
              : "#ffffff",
        }}
      >
        <div style={{ display: "flex", flexDirection: "row" }}>
          <div style={{ display: "flex" }}>
            <img
              className="mms-report-image"
              style={{ width: "180px", cursor: "pointer", maxHeight: "160px" }}
              src={this.props.Image}
              onClick={() => {
                this.props.ChooseFocus(this.props.Index);
              }}
            />
          </div>
          <div
            style={{
              display: "flex",
              width: "100%",
              flexDirection: "column",
              paddingLeft: 5,
              fontFamily:
                "Avenir Next W01,Avenir Next W00,Avenir Next,Avenir,Helvetica Neue,sans-serif",
            }}
          >
            <div style={{ display: "flex", flexDirection: "row" }}>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <div
                  onClick={() => {
                    this.props.ChooseFocus(this.props.Index);
                  }}
                  className="mms-report-title"
                  style={{ cursor: "pointer" }}
                >
                  <b>Class {this.props.Data.mms_class}:</b>
                  {this.props.Data.mms_damage_typ} (#{this.props.Data.label_id})
                </div>
                <div className="mms-report-time">
                  {this.props.Data.date_collected +
                    " at " +
                    this.props.Data.timestamp.substring(0, 5)}
                </div>
                <div className="mms-report-info">
                  <h4 className="info-header">Service Window:</h4>
                  <p>Repair by {this.props.Data.mms_due_display}</p>
                  <h4 className="info-header">Location:</h4>
                  <p>{this.props.Data.Address}</p>
                </div>
              </div>
              <div style={{ minWidth: 15, flex: 1 }} />
              <div style={{ width: "150px" }}>
                <div
                  style={{ display: "flex", flexDirection: "column" }}
                  className="mms-report-options"
                >
                  <div className="report-resolution">Resolution:</div>
                  <div
                    style={{ display: "flex", width: "100%", height: "40px" }}
                  >
                    {this.state.showMenu ? (
                      <div
                        style={{
                          zIndex: 10,
                          position: "relative",
                          top: -10,
                          display: "flex",
                          width: "100%",
                        }}
                      >
                        <div
                          ref={(element) => {
                            this.dropdownMenu = element;
                          }}
                          className="menu"
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            position: "absolute",
                          }}
                        >
                          {/* Renders the resolution buttons */}
                          {this.resolution.map((item, index) => (
                            <button
                              key={index}
                              style={{ cursor: "pointer", width: "120px" }}
                              onClick={(event) => {
                                this.setMenu(
                                  event,
                                  event.target.innerText,
                                  item.label
                                );
                              }}
                            >
                              {" "}
                              {item.title}{" "}
                            </button>
                          ))}
                        </div>
                      </div>
                    ) : null}
                    <div>
                      <div
                        ref={(element) => {
                          this.dropdownMenu = element;
                        }}
                        className="menu"
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          position: "relative",
                        }}
                      >
                        {/* Renders the current resolution as a button */}
                        <button
                          style={{ cursor: "pointer", width: "120px" }}
                          onClick={this.showMenu}
                        >
                          {" "}
                          {this.state.currentMenu}{" "}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                {/* Renders the print/share/download buttons */}
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      cursor: "pointer",
                      justifyContent: "right",
                    }}
                    onClick={() => {
                      this.props.ShareItem(this.props.Index);
                    }}
                  >
                    <div className="icon-description">Share</div>
                    <div>
                      <img
                        src={share_icon}
                        style={{ height: 20, paddingRight: 5 }}
                      />
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      cursor: "pointer",
                      justifyContent: "right",
                    }}
                    onClick={() => {
                      this.props.PrintItem(this.props.Index);
                    }}
                  >
                    <div className="icon-description">Print</div>
                    <div style={{}}>
                      <img
                        src={print_icon}
                        style={{ height: 20, paddingRight: 8 }}
                      />
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      cursor: "pointer",
                      justifyContent: "right",
                    }}
                    onClick={() => {
                      this.props.DownloadItem(this.props.Index);
                    }}
                  >
                    <div className="icon-description">Download</div>
                    <div style={{}}>
                      <img
                        src={download_icon}
                        style={{ height: 20, paddingRight: 8 }}
                      />
                    </div>
                  </div>
                </div>
                <div style={{ flex: 1 }} />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withModal(DailyAlert);
