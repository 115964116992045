import React, { useState, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { TOOL_BAR_HEIGHT } from "../utils/theme/constrains";
import { AuthContext } from "./AuthContext";
import { HomePageContext } from "./MainContext";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IrisCityLogo from "../assets/IrisCityLogo.png";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";

const useStyles = makeStyles((theme) => ({
  appbar: {
    zIndex: theme.zIndex.drawer + 1,
    height: TOOL_BAR_HEIGHT,
    backgroundColor: theme.palette.grey[100],
    color: theme.palette.text.primary,
  },
  toolbar: {
    display: "flex",
    justifyContent: "space-between",
    height: TOOL_BAR_HEIGHT,
  },
  siteLogo: {
    objectFit: "contain",
    height: TOOL_BAR_HEIGHT - 20,
    marginTop: "8px",
  },
  menuButton: {
    borderRadius: "0",
    height: TOOL_BAR_HEIGHT,
    marginTop: "3px",
  },
  menuText: {
    marginTop: "3px",
    marginRight: "10px",
    marginLeft: "10px",
  },
}));

export default function Header(props) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const { showPreference, setShowPreference } = useContext(HomePageContext);

  const {
    contextGroupInfo,
    contextUsername,
    setContextUsername,
    contextCity,
  } = useContext(AuthContext);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleLogout = () => {
    setAnchorEl(null);
    setContextUsername();
  };

  const handlePreference = () => {
    window.gtag(
      "event",
      `${contextCity.name}_${contextUsername}_open_preference`
    );
    setShowPreference(!showPreference);
    setAnchorEl(null);
  };

  const handleAdmin = () => {
    window.gtag("event", `${contextCity.name}_${contextUsername}_open_admin`);
    props.history.push("/admin");
    setAnchorEl(null);
  };

  return (
    <AppBar position="fixed" className={classes.appbar} elevation={1}>
      <Toolbar className={classes.toolbar}>
        <img src={IrisCityLogo} className={classes.siteLogo} alt="IrisGO" />
        <IconButton
          aria-label="account of current user"
          aria-controls="menu-appbar"
          aria-haspopup="true"
          onClick={handleMenu}
          color="inherit"
          className={classes.menuButton}
        >
          <Typography variant="h6" className={classes.menuText}>
            {contextUsername}
          </Typography>
          <MenuIcon />
        </IconButton>
        <Menu
          anchorEl={anchorEl}
          keepMounted
          open={open}
          getContentAnchorEl={null}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        >
          {contextGroupInfo.indexOf("admin") >= 0 && (
            <MenuItem onClick={handleAdmin}>Admin</MenuItem>
          )}
          <MenuItem onClick={handlePreference}>Preferences</MenuItem>
          <MenuItem onClick={handleLogout}>Logout</MenuItem>
        </Menu>
      </Toolbar>
    </AppBar>
  );
}
